import { useEffect, useState } from 'preact/hooks';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';
import { clone } from '../../../utilities/obj.js';
import { SlateNode } from '../types.ts';
import { useRemapTime } from './useRemapTime.ts';

export const useLocalizedTranscriptDocument = (
  unlocalizedTranscriptDocument: SlateNode[],
): SlateNode[] => {
  const { player } = useWistiaPlayerContext();

  const remapTime = useRemapTime();

  const [localizedTranscriptDocument, setLocalizedTranscriptDocument] = useState<SlateNode[]>(
    unlocalizedTranscriptDocument,
  );

  useEffect(() => {
    player.hasData(() => {
      const newDocument = clone(unlocalizedTranscriptDocument) as SlateNode[];
      const sourceLanguage = player.languages().find((lang) => lang.sourceLanguage);
      const playerLanguage = player.language();
      if (playerLanguage?.ietfLanguageTag === sourceLanguage?.ietfLanguageTag) {
        return;
      }

      if (sourceLanguage == null || playerLanguage == null) {
        return;
      }

      newDocument.forEach((element) => {
        if (element.type === 'paragraph' && element.children != null) {
          element.children.forEach((child) => {
            if (child.type === 'timed-words') {
              child.words.forEach((word) => {
                const startTime =
                  remapTime(
                    sourceLanguage.ietfLanguageTag,
                    playerLanguage.ietfLanguageTag,
                    word[1] / 1000,
                  ) * 1000;
                const endTime =
                  remapTime(
                    sourceLanguage.ietfLanguageTag,
                    playerLanguage.ietfLanguageTag,
                    word[2] / 1000,
                  ) * 1000;
                // eslint-disable-next-line no-param-reassign
                word[1] = startTime;
                // eslint-disable-next-line no-param-reassign
                word[2] = endTime;
              });
            }
          });
        }
      });

      setLocalizedTranscriptDocument(newDocument);
    });
  }, [player, unlocalizedTranscriptDocument, player.hashedId(), remapTime]);

  return localizedTranscriptDocument.length > 0
    ? localizedTranscriptDocument
    : unlocalizedTranscriptDocument;
};
